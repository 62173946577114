import {
  SET_USER,
  CLEAR_USER,

  SET_PROFILE,
  CLEAR_PROFILE,

  RESET_REFERENCES,
} from "./actionTypes";

const initialState = {
  users: {},
  profiles: {},
}

export default function referenceReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_REFERENCES: {
      return {
        users: {},
        profiles: {},
      }
    }

    case SET_USER: {
      const usersProcessed = state.users;
      usersProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case CLEAR_USER: {
      const usersProcessed = state.users;
      delete usersProcessed[action.payload];
      return {
        ...state,
        users: usersProcessed,
      }
    }

    case SET_PROFILE: {
      const profilesProcessed = state.profiles;
      profilesProcessed[action.payload.userId] = action.payload;
      return {
        ...state,
        profiles: profilesProcessed,
      }
    }
    case CLEAR_PROFILE: {
      const profilesProcessed = state.profiles;
      delete profilesProcessed[action.payload];
      return {
        ...state,
        profiles: profilesProcessed,
      }
    }

    default:
      return state
  }
}
