import Button from './blocks/Button';
import Fab from './blocks/Fab';
import LinkButton from './blocks/LinkButton';
import Input from './blocks/Input';
import Select from './blocks/Select';
import TextArea from './blocks/TextArea';
import TextAreaRich from './blocks/TextAreaRich';
import ProgressBar from './blocks/ProgressBar';
import MenuSide from './blocks/MenuSide';
import MenuDropdown from './blocks/MenuDropdown';
import Dialog from './blocks/Dialog';
import SearchBar from './blocks/SearchBar';
import Typography from './blocks/Typography';
import CheckBox from './blocks/CheckBox';

const paletteData = {
  blank: {
    standard: {
      foreground: '#000000',
      background: 'rgba(0, 0, 0, 0.0)',
    },
    highlight: {
      foreground: '#000000',
      background: 'rgba(0, 0, 0, 0.0)',
    },
  },
  primary: {
    standard: {
      foreground: '#ffffff',
      background: '#252525',
    },
    highlight: {
      foreground: '#ffffff',
      background: '#252525',
    },
  },
  secondary: {
    standard: {
      foreground: '#252525',
      background: '#ffffff',
    },
    highlight: {
      foreground: '#252525',
      background: '#ffffff',
    },
  },
  soft: {
    standard: {
      foreground: '#000000',
      background: '#252525',
    },
    highlight: {
      foreground: '#000000',
      background: '#252525',
    },
  }
}

export {Button, Fab, LinkButton, Input, TextArea, TextAreaRich, ProgressBar, MenuSide, MenuDropdown, Select, Dialog, SearchBar, Typography, CheckBox, paletteData};
