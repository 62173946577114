import React from 'react';
import PropTypes from 'prop-types';

import {paletteData} from '../../../styles';

function Typography({children, style, size, onClick, palette}) {
  return (
    <div
      onClick={onClick}
      style={{
        color: paletteData[palette || 'blank'].standard.foreground,
        fontSize: (size === 'heading' ? '3em' :
                   size === 'subHeading' ? '2em' :
                   size === 'title' ? '1.5em' :
                   size === 'text' ? '1em' :
                   size === 'subText' ? '.8em' :
                   '1em'),
        ...style,
      }}
    >
      {children}
    </div>
  );
}

Typography.propTypes = {
  palette: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default Typography;
