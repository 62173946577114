import React from "react";
import {connect} from 'react-redux';

import {
  Switch,
  Route,
  Link,
} from "react-router-dom";

import Home from '../pages/public/home';
import NavigationError from '../pages/public/navigationError';

import {GridContainer, GridCell} from '../components/grid';
import {Typography, paletteData} from '../components/styles';

import {setApiToken} from '../store/reducers/auth/actions.js';
import {setProfile} from '../store/reducers/user/actions.js';

function Routes() {
  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <GridContainer style={{
          height: 80,
          background: paletteData.primary.standard.background,
        }}>
          <GridCell center={true} style={{margin: 5}}>
            <Link to='/'>
              <img
                style={{maxWidth: 100}}
                src='https://res.cloudinary.com/doccjcjez/image/upload/assets/logo.png'
                alt='red inc logo'
              />
            </Link>
          </GridCell>
          <GridCell center={true}>
            <Link to='/' style={{textDecoration: 'none'}}>
              <Typography size='title' style={{color: paletteData.primary.standard.foreground, marginLeft: 10}}>
                Red Inc Transmedia
              </Typography>
              <Typography size='subTitle' style={{color: paletteData.primary.standard.foreground, marginLeft: 10}}>
                Make Life Easy
              </Typography>
            </Link>
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="*">
            <NavigationError />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {setApiToken, setProfile})(Routes);
