import React from 'react';

import {Typography} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';

function Home() {

  return (
    <div style={{
      flex: 1,
      background: 'url("https://res.cloudinary.com/doccjcjez/image/upload/c_scale,w_2048,q_auto:good/promotional/chill-guy-high-tatras-91224-l.jpg") no-repeat center center',
      backgroundSize: 'cover',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <GridContainer style={{flex: 1}}>
        <GridCell center={true} weight={1} centerWeights={{top: 2, bottom: 3}} style={{textAlign: 'center'}}>
          <div align="center">
            <img
              style={{maxHeight: 200}}
              src="https://res.cloudinary.com/doccjcjez/image/upload/assets/logo.png"
              alt="Red Inc Logo"
            />
          </div>
          <Typography
            size='subHeading'
            style={{
              color: '#9b0000',
              textShadow: '1px 1px #000000',
            }}>
            Make Life Easy
          </Typography>
        </GridCell>
      </GridContainer>
      <div style={{textAlign: 'right', padding: 20}}>
        <Typography size='subText' style={{color: 'white'}}>Email <a href="mailto:alex@redincmedia.co.nz" style={{color: 'white'}}>Admin</a> | Copyright 2021 Red Inc Transmedia</Typography>
      </div>
    </div>
  );
}

export default Home;
