import {
  SET_USERS,
  ADD_USERS,
  ADD_USER,
  REPLACE_USER,
  REMOVE_USER,

  SET_SEARCH,
  RESET_LISTS,
} from "./actionTypes";

const initialState = {
  users: undefined,
  search: {
    users: {text: '', filter: undefined, queryDepth: 0},
  }
}

export default function listsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH: {
      const searchProcessed = state.search;
      searchProcessed[action.payload.name] = action.payload.value;
      return {
        ...state,
        search: searchProcessed,
      }
    }
    case RESET_LISTS: {
      return {
        users: undefined,
        search: {
          users: {text: '', filter: undefined, queryDepth: 0},
        }
      }
    }

    case SET_USERS: {
      return {
        ...state,
        users: action.payload,
      }
    }
    case ADD_USER: {
      const usersProcessed = state.users || [];
      usersProcessed.push(action.payload);
      usersProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case ADD_USERS: {
      return {
        ...state,
        users: (state.users || []).concat(action.payload),
      }
    }
    case REPLACE_USER: {
      const usersProcessed = state.users || [];
      usersProcessed.splice(action.payload.index, 1, action.payload.user);
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case REMOVE_USER: {
      const usersProcessed = state.users || [];
      usersProcessed.splice(action.payload, 1);
      return {
        ...state,
        users: usersProcessed,
      }
    }

    default:
      return state
  }
}
