import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import {paletteData} from '../../../styles';

function Button({children, to, style, palette, onClick}) {
  return (
    <Link
      style={{
        backgroundColor: palette ? paletteData[palette]['standard'].background : paletteData['blank']['standard'].background,
        color: palette ? paletteData[palette]['standard'].foreground : paletteData['blank']['standard'].foreground,
        border: 'none',
        outline: 'none',
        textDecoration: 'none',
        padding: 10,
        borderRadius: 3,
        cursor: 'pointer',
        transition: '0.3s',
        display: 'inline',
        fontSize: '1em',
        ...style,
      }}
      onClick={() => {
        if(onClick) {
          onClick();
        }
      }}
      to={to}
    >
      {children}
    </Link>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
  palette: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Button;
